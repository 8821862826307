import { Center, Loader, Text } from '@mantine/core';

import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import formatCurrency from '../utils/formatCurrency';

const PurchaseOrderLines = ({
  poLines,
  isLoading,
  error,
  onRowUpdate,
  ...rest
}) => {
  /**
   * Sample Purchase Order Line Item...
   *
   * {
   *  "LineNum": 0,
   *  "Description": "Pre employment Medical exam - Mmelesi expires 23/07/2024 (1 x 1060,87)",
   *  "Quantity": ".000000",
   *  "UnitPrice": "1060.870000",
   *  "LineTotal": "1060.870000",
   *  "LineStatus": "O",
   *  "LineFormatCode": "13430103017605"
   * }
   */
  const columns = [
    {
      name: 'LineFormatCode',
      header: 'Code',
      defaultVisible: false,
      defaultFlex: 1,
      sortable: false,
    },
    {
      name: 'Description',
      header: 'Description',
      defaultFlex: 3,
      sortable: false,
    },
    {
      name: 'Quantity',
      header: 'Quantity',
      render: ({ value }) => Number(value).toFixed(2),
      defaultFlex: 1,
      headerAlign: 'center',
      textAlign: 'end',
      sortable: false,
    },
    {
      name: 'UnitPrice',
      header: 'Unit Price',
      render: ({ value }) => formatCurrency(value),
      defaultFlex: 1,
      textAlign: 'end',
      headerAlign: 'center',
      sortable: false,
    },
    {
      name: 'LineTotal',
      header: 'Line Total',
      render: ({ value }) => formatCurrency(value),
      defaultFlex: 1,
      textAlign: 'end',
      headerAlign: 'center',
      sortable: false,
    },
    {
      name: 'LineStatus',
      header: 'Line Status',
      defaultFlex: 1,
      textAlign: 'center',
      sortable: false,
    },
    {
      name: 'Matches',
      header: 'Matches Invoice',
      render: ({ value, data }) => (
        <input
          type="checkbox"
          checked={!!value}
          onChange={(e) => {
            const updatedRow = { ...data, Matches: e.target.checked };

            if (typeof onRowUpdate === 'function') {
              onRowUpdate(updatedRow, poLines);
            }
          }}
        />
      ),
      defaultFlex: 1,
      textAlign: 'center',
      sortable: false,
    },
  ];

  const getRowClassName = ({ data }) => {
    return data.LineStatus === 'C' ? 'row-disabled' : '';
  };

  if (isLoading) {
    return (
      <div {...rest}>
        <Center maw={300} h={300} m="auto">
          <Loader color="cyan" />
        </Center>
      </div>
    );
  }

  if (error) {
    return (
      <div {...rest}>
        <Center maw={300} h={300} m="auto">
          <Text size={'sm'} color="red">
            Failed to fetch purchase order line items.
          </Text>
        </Center>
      </div>
    );
  }

  return (
    <div {...rest}>
      <ReactDataGrid
        idProperty="id"
        columns={columns}
        rowClassName={getRowClassName}
        dataSource={poLines}></ReactDataGrid>
    </div>
  );
};

export default PurchaseOrderLines;
